import * as React from 'react';

export interface StepProps {
  number: string;
  title: string;
  description: string;
  imageSrc: string;
  imageAlt: string;
}

export const HowItWorks: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState<'seller' | 'buyer'>(
    'seller'
  );

  const selletSteps: StepProps[] = [
    {
      number: '01',
      title: 'Quick Start',
      description: 'Sign in with your email and link your bank account',
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/99dd301ebd4619c7e73c0800c036070204d9454b61d1aa47b1bccfa55d1dc396?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      imageAlt: 'Quick Start process illustration',
    },
    {
      number: '02',
      title: 'Import & Share',
      description: 'Import existing sales and accept stablecoins payments',
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/83cc347bd9a9d4db43e9bfdc006617f5d40212c22d7aeb94fe1cb584d5a10ed6?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      imageAlt: 'Import & Share process illustration',
    },
    {
      number: '03',
      title: 'Free Smart Contracts',
      description: 'Accept offers with smart contracts and DocuSign',
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/a1675b857167e939f1ae66795937561ace6c4ba920a359f4dcea9f1003b70400?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      imageAlt: 'Offer evaluation process illustration',
    },
    {
      number: '04',
      title: 'Same-Day Payment',
      description: 'Receive funds in your bank local currency',
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/925ccbde140b8ad961db7af7bbaffb9fe4dd2f15f08d0ec21e34d462b14541f7?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      imageAlt: 'Payment withdrawal process illustration',
    },
  ];

  const buyerSteps: StepProps[] = [
    {
      number: '01',
      title: 'View Sale Offer',
      description: 'Access sale offer via G8WY payment link',
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/17f5a527b6c92f460f6b256c2c189034fbe3c463c425d3ab672765167354c546?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      imageAlt: 'View sale offer illustration',
    },
    {
      number: '02',
      title: 'Purchase Intent',
      description: 'Complete KYC and sign intent to purchase',
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/a81b8026dc40da6a09f15fc69d0129cd5caad9cffde3f1fd436aef13d27e57fc?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      imageAlt: 'Purchase intent illustration',
    },
    {
      number: '03',
      title: 'Initiate Payment',
      description: 'Get stablecoins and make the payment',
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/a1675b857167e939f1ae66795937561ace6c4ba920a359f4dcea9f1003b70400?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      imageAlt: 'Payment initiation illustration',
    },
    {
      number: '04',
      title: 'Sign Contract',
      description: 'Sign contract and receive property deed',
      imageSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/10546d1b4197dd0743c68be4d4e3fcce99e1de6984682869dbb57d1468959197?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd',
      imageAlt: 'Contract signing illustration',
    },
  ];

  const steps = activeTab === 'seller' ? selletSteps : buyerSteps;

  const Step: React.FC<StepProps> = ({
    number,
    title,
    description,
    imageSrc,
    imageAlt,
  }) => {
    return (
      <div className="flex flex-wrap flex-1 shrink gap-6 items-center basis-0 min-w-[240px] max-md:max-w-full">
        <div className="flex flex-col flex-1 shrink self-stretch px-6 my-auto basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full">
          <h3 className="text-4xl font-extrabold tracking-tight leading-tight max-md:max-w-full">
            {number}
          </h3>
          <div className="flex flex-col mt-12 w-full text-slate-900 max-md:mt-10 max-md:max-w-full">
            <h4 className="text-2xl font-semibold leading-none max-md:max-w-full">
              {title}
            </h4>
            <p className="mt-2 text-xl leading-tight max-md:max-w-full">
              {description}
            </p>
          </div>
        </div>
        <img
          loading="lazy"
          src={imageSrc}
          alt={imageAlt}
          className="object-contain self-stretch my-auto rounded-md aspect-[1.51] min-w-[240px] w-[242px]"
        />
      </div>
    );
  };

  return (
    <section className="flex flex-col">
      <h2 className="text-5xl font-extrabold tracking-tight leading-none text-slate-900 max-md:max-w-full max-md:text-4xl">
        How it Works?
      </h2>

      <div className="flex flex-col mt-10 w-full max-md:max-w-full">
        <nav className="flex self-start p-1.5 text-base font-medium whitespace-nowrap rounded-md bg-slate-100 min-h-[56px]">
          <button
            onClick={() => setActiveTab('seller')}
            className={`gap-2.5 self-stretch px-4 py-1.5 h-11 rounded ${
              activeTab === 'seller'
                ? 'bg-white text-slate-900'
                : 'text-slate-700'
            }`}
          >
            Seller
          </button>
          <button
            onClick={() => setActiveTab('buyer')}
            className={`gap-2.5 self-stretch px-4 py-1.5 h-11 rounded ${
              activeTab === 'buyer'
                ? 'bg-white text-slate-900'
                : 'text-slate-700'
            }`}
          >
            Buyer
          </button>
        </nav>

        <div className="flex flex-col mt-4 w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-10 items-start w-full max-md:max-w-full">
            {steps.slice(0, 2).map((step) => (
              <Step key={step.number} {...step} />
            ))}
          </div>
          <div className="flex flex-wrap gap-10 items-start mt-10 w-full max-md:max-w-full">
            {steps.slice(2, 4).map((step) => (
              <Step key={step.number} {...step} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
