import React from 'react';

import { HeroSection } from '../Hero/HeroSection';
import { HowItWorks } from './HowItWorkSection';
import { CryptoGuide } from './CryptoGuideSection';
import { SuccessStories } from './SucessStoriesSection';

const HomeContent = () => {
  return (
    <section className="mt-16 flex flex-col gap-32 whitespace-pre-wrap">
      <HeroSection
        title="Real Estate. Unreal Speed"
        backgroundImage="https://cdn.builder.io/api/v1/image/assets/TEMP/0d819d6f133c6cbb027cf8c4a431ea0a9fbcf17255cce797a6bd86459c636040?placeholderIfAbsent=true&apiKey=466c088da2e142d481f76ae5c06a8ddd"
      />
      <HowItWorks />
      <CryptoGuide />
      <SuccessStories />
    </section>
  );
};

export default HomeContent;
